import useCustomer from '@/views/customer-hotel/useCustomer'
import CustomerModal from '@/modal/customerModal'
import {
  computed, reactive, ref, watch,
} from '@vue/composition-api'
import util from '@/global/util'
import http from '@/global/http'
import constant from '@/global/const'
import store from '@/store'
import { getUserData } from '@/auth/utils'

export default function useCustomerDetail() {
  const {
    actions,
    customerMenuType,
    customerMenuTypeVI,
    //
    fetchCustomer,
    quotation,
    closeDealCustomer,
    openDealCustomer,
    fetchSuggestedListProduct,
    fetchHistoryUpdateCustomer,
  } = useCustomer()
  const customer = CustomerModal()
  const historyUpdate = ref([])
  const progressExportPdf = ref(0)
  const isDownloadedPdf = ref(true)
  const userData = getUserData()
  const suggestedList = ref([])
  const suggestSelectedObject = ref({})
  const suggestSelectedList = ref([])
  const routeParams = ref({})
  const totalSuggestedList = ref(0)
  const fitFilter = reactive({
    is_not_exported: ref(null),
    user_id: ref(null),
    price_from: ref(null),
    price_to: ref(null),
    q: ref(null),
  })
  const countBase64ImgLoaded = ref(0)
  const countBase64ImgLoading = ref(0)
  const refreshKey = ref(0)
  const base64ImgObj = ref({})
  const tableColumnsSuggestedProduct = [
    {
      label: 'Chọn',
      key: 'checked',
    },
    {
      label: 'Mã',
      key: 'id',
      tdClass: 'text-primary position-relative',
    },
    {
      label: 'Ảnh',
      key: 'image',
    },
    {
      label: 'Tiêu đề',
      key: 'title',
      tdClass: 'position-relative',
    },
    {
      label: 'Kết cấu',
      key: 'ket-cau',
      formatter: (value, key, item) => ([
        item.width && item.length ? `${item.width}x${item.length}m` : '',
        item.basement ? '1 hầm, ' : '',
        ' 1 trệt,',
        item.badger ? '1 lửng, ' : '',
        item.floor_number ? `${item.floor_number} lầu` : '',
        item.terrace ? 'ST' : '',
      ].join('')),
    },
    {
      label: 'Giá tổng',
      key: 'gia-tong',
      tdClass: 'text-nowrap',
      formatter: (value, key, item) => {
        let { price } = item
        if (item.currency.toLowerCase() === 'million') {
          price = item.price / 1000000
        }
        if (item.currency.toLowerCase() === 'billion') {
          price = item.price / 1000000000
        }
        return `${price} ${(constant.CURRENCY.find(c => c.value === (item.currency || '')).text || '').toUpperCase()}`
      },
    },
    {
      label: 'Người đăng',
      key: 'user.fullname',
      tdClass: 'text-primary',
    },
  ]
  const tableColumnsSuggestedProductExport = [
    {
      label: 'Chọn',
      key: 'checked',
    },
    {
      label: 'Mã',
      key: 'id',
      tdClass: 'text-primary position-relative',
    },
    {
      label: 'Ảnh',
      key: 'image',
    },
    {
      label: 'Nội dung',
      key: 'content',
    },
    {
      label: 'Người đăng',
      key: 'user.fullname',
      tdClass: 'text-primary',
    },
  ]
  const tableColumnsHistoryUpdate = [
    {
      label: 'Ngày Nhập',
      key: 'created_at',
      formatter: (value, key, item) => util.formatDate(value),
    },
    {
      label: 'Tên',
      key: 'creator.fullname',
    },
    {
      label: 'Số ĐT',
      key: 'data.host_phone1',
    },
    {
      label: 'Nguồn',
      key: 'data.host_name',
    },
    {
      label: 'Nhu Cầu',
      key: 'data.title',
      tdClass: 'py-1 minw-250',
    },
    {
      label: 'Ngành Nghề',
      key: 'data.career',
    },
    {
      label: 'Giá',
      key: 'data.price',
    },
    {
      label: 'Gặp + Thay Đỗi Nhu Cầu',
      key: 'data.meeting_count',
    },
    {
      label: 'Tình Trạng',
      key: 'data.status',
    },
    {
      label: 'Ghi Chú',
      key: 'data.note',
      tdClass: 'minw-250',
    },
    {
      label: 'Tương Tác Gần nhất',
      key: 'updated_at',
      formatter: (value, key, item) => util.formatDate(value),
    },
    {
      label: 'Đã Chốt',
      key: 'data.confirm_deal_count',
    },
  ]
  const perPage = ref(10)
  const perPageOptions = [10, 25, 50, 100]
  const currentPage = ref(1)
  const users = computed(() => store.state.app.users)

  // show old product with image _160 resolution
  const showImage160 = ref([])

  const removeContact = str => {
    let rs = str || ''
    rs = rs.split('Liên hệ')[0] || rs
    rs = rs.split('Li&ecirc;n hệ')[0] || rs
    rs = rs.split('&nbsp&nbsp&nbsp')[0] || rs
    return rs.replaceAll('<p><br></p>', '')
  }
  const fetchHistoryUpdate = (data, cb) => fetchHistoryUpdateCustomer(data, res => {
    historyUpdate.value = res && res.data
    historyUpdate.value.reverse()
    return cb && cb(res)
  })

  const fetchSuggestedList = (id, cb) => fetchSuggestedListProduct({
    id: id || routeParams.value.id,
    q: http.buildQuery({
      page: currentPage.value - 1,
      perpage: perPage.value,
      ...fitFilter,
    }),
  }, res => {
    const result = res.data.result || []
    suggestedList.value = result.map(item => ({ ...item, content: removeContact(item.content) }))
    totalSuggestedList.value = res.data.total || 0
    return cb && cb(res)
  })

  watch(fitFilter, () => {
    fetchSuggestedList()
  })

  watch(suggestSelectedObject, () => {
    Object.keys(suggestSelectedObject.value).forEach(id => {
      if (!suggestSelectedObject.value[id]) {
        suggestSelectedList.value = suggestSelectedList.value.filter(item => item.id.toString() !== id.toString()) || []
      } else {
        const found = suggestSelectedList.value.find(item => item.id.toString() === id.toString())
        if (!found) {
          suggestSelectedList.value.push(suggestedList.value.find(item => item.id.toString() === id.toString()))
        }
      }
    })
  }, { deep: true })

  watch([currentPage, perPage], () => {
    fetchSuggestedList()
  })

  return {
    customer,
    customerMenuType,
    customerMenuTypeVI,
    actions,
    suggestedList,
    suggestSelectedObject,
    suggestSelectedList,
    tableColumnsSuggestedProduct,
    tableColumnsSuggestedProductExport,
    tableColumnsHistoryUpdate,
    totalSuggestedList,
    showImage160,
    perPage,
    perPageOptions,
    currentPage,
    routeParams,
    fitFilter,
    users,
    userData,
    isDownloadedPdf,
    progressExportPdf,
    countBase64ImgLoaded,
    countBase64ImgLoading,
    base64ImgObj,
    refreshKey,
    historyUpdate,
    //
    fetchCustomer,
    quotation,
    closeDealCustomer,
    fetchSuggestedList,
    openDealCustomer,
    fetchHistoryUpdate,
  }
}
